<template>
	<div>
		<div class="row">
			<div class="col-6">
				<SeasonInput v-model="season" @change="changeSeason" />
			</div>
			<div class="col-6">
				<e-select
					v-model="sort_type"
					:options="all_sort"
					:allow-empty="false"
					:show-labels="false"
					@input="sortStat"
				>
                    <template slot="option" slot-scope="{ option }">{{ $t('monte.stat_sort.'+option) }}</template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.stat_sort.'+option) }}</template>
                </e-select>
			</div>
		</div>
		<div v-if="loading">
			<LoadingSpinner />
		</div>
		<div v-else-if="stat.length > 0">
			<div class="table-responsive m-0 mt-4" style="height: 70vh;">
				<table class="table b-table table-striped table-hover table-borderless stat_monte">
					<thead class="sticky">
						<tr>
							<th class="bg-header" colspan="1"></th>
							<th v-for="month in months" class="border-left border-right border-bottom text-center" :colspan="month.days" :key="month.name">
								{{ month.name }}
							</th>
						</tr>
						<tr>
							<td class="bg-header" colspan="1"></td>
							<td v-for="(day, key) in calendar" class="border text-center" :key="key">{{ day.label }}</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(mare, index) in stat" :key="mare.horse_id">
							<td :class="mare.dg_color+' border'">
								<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: mare.horse_id }}">#{{ index+1 }} {{ mare.horse_nom }} {{ mare.couple_dg }}</router-link>
								<span v-if="mare.status"><br/>{{ $t('gynecologie.'+mare.status) }} {{ dpsDateFormat(mare.dps) }}</span>
							</td>
							<td v-for="(day, key) in calendar" class="border text-center" :key="key">
								<span v-if="day.date in mare.saillies" :class="{ 'green': mare.saillies[day.date] == '+', 'orange': mare.saillies[day.date] == '-' }">
									S
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="table-responsive mt-3 mx-3">
				S : {{ $t('monte.stat_monte.saillie_sans_dg') }}  <span class="ml-3 green">S</span> : {{ $t('monte.stat_monte.saillie_dg_plus') }}   <span class="ml-3 orange">S</span> : {{ $t('monte.stat_monte.saillie_dg_moins') }}
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import Gynecologie from "@/mixins/Gynecologie.js"
import Vue from 'vue'
import _sortBy from "lodash/sortBy"

export default {
	name: 'StatSaillies',
	mixins: [Gynecologie],
	props:['horse_id'],
	data () {
		return {
			season: null,
			loading: false,
			stat: [],
			calendar: [],
			months: [],
			sort_type: 'dps',
			all_sort: ['name', 'dps']
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		async init_component() {

		},

		async changeSeason() {
			this.generateMonthArray(this.season.begin.getYear())
			this.generateDatesArray(this.season.begin, this.season.end)
			this.loading = true
			this.stat = await this.getStatSailliesByStallionAndSeason(this.season.id, this.horse_id)
			this.sortStat()
			this.loading = false
		},

		generateDatesArray(start, end) {
			let arr = []
			for(let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate()+1)){
		        arr.push({
		        	date: this.formatDate(new Date(dt)),
		        	label: new Date(dt).toLocaleDateString(Vue.i18n.locale(), {day: "numeric"}).padStart(2, "0")
		        });
		    }
		    this.calendar = arr
		},

		generateMonthArray(year) {
			this.months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(mon => {
				const name = new Date(year, mon).toLocaleString(Vue.i18n.locale(), {month: 'long'})
				return {
					'name': name.charAt(0).toUpperCase() + name.slice(1),
					'days': new Date(year, mon+1, 0).getDate()
				}
			})
		},

		formatDate(date) {
			var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

			if (month.length < 2) 
				month = '0' + month;
			if (day.length < 2) 
				day = '0' + day;

			return [year, month, day].join('-');
		},

		dpsDateFormat(date){
			if (!date) return
			return new Date(date * 1000).toLocaleDateString(Vue.i18n.locale())
		},

		sortStat() {
			if(this.sort_type == "name") {
				this.stat = _sortBy(this.stat, 'horse_nom')
			}
			else if(this.sort_type == "dps") {
				this.stat = _sortBy(this.stat, 'dps')
			}
		}
	},

	components: {
		LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		SeasonInput : () => import('@/components/Inputs/SeasonInput'),
	}
}

</script>